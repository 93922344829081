<template>
  <div class="view-service">
    <div class="header-img-box">
      <img class="w100" src="@/assets/images/all/pc-icon1.png" alt="" srcset="">
    </div>
    <div class="faq-list">
      <div class="faq-title flex-c">
        <div class="title text-c">
          The Installation Method and Instructions for Vinyl Tile
        </div>
      </div>
      <div class="faq-line">
        <div v-for="item,index in serverList" :key="60+index" class="text-box">
          <span class="num">{{item.num}}</span>
          <span class="text">
            {{item.content}}
          </span>
        </div>
      </div>
      <div class="center-img">
        <img class="w100" src="@/assets/images/all/pc-icon2.png" alt="" srcset="">
      </div>
      <div class="faq-title flex-c">
        Simple Maintenance Method:
      </div>
      <div class="faq-line faq-line2">
        <div v-for="item,index in serverList2" :key="90+index" class="text-box">
          <span class="num">{{item.num}}</span>
          <span class="text">
            {{item.content}}
          </span>
        </div>
        <div v-for="item,index in serverList3" :key="100+index" class="text-box text-box3" :class="{'flex-row':item.isright}">
          <img class="img" :src="item.img" alt="" srcset="">
          <div class="text">
            {{item.content}}
          </div>
        </div>
      </div>
      <div class="notice-box">
        <div class="notice-top flex-c">
          <img style="width:0.78rem;margin-right:0.26rem" src="@/assets/images/all/pc-icon3.png" alt="" srcset="">
          <div class="notice-title">NOTICE</div>
        </div>
        <div class="notice-main flex-c text-c">
          Tiles should be kept in warm conditions for 24 hours before laying check that the floor is dry,smooth,level,clean and completely DUST FREE.If you intend laying over existing tiles,ensure that these are firmly stuck down-if
          doubt,remove them.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverList: [
        {
          num: '01',
          content: 'The floor tiles should not be installed before the completion of upholstery (including paint), unless there is proper covering protection to prevent the tiles from being polluted or damaged.'
        },
        {
          num: '02',
          content: 'The tiles can not be drenched or shined directly; it should be placed flatly with the room temperature over 20.'
        },
        {
          num: '03',
          content: 'The floor tiles should not be installed before the completion of upholstery (including paint), unless there is proper covering protection to prevent the tiles from being polluted or damaged.'
        },
        {
          num: '04/05',
          content: 'Before installation, thoroughly clean the floor. If uneven, it should firstly be rubbed out or made even. If there is much gravel, it should be stuccoed again.'
        },
        {
          num: '06',
          content: 'Make the referential ink line according to the building in order to get the center, then measure 90°with set square to get the vertical equal line, and finally, flip the datum line with ink.'
        },
        {
          num: '07',
          content: 'Regard the datum line center as the starting point and then daub the floor glue with steel knife towards one of the lines; after 20 or 30 minutes, you can start the installation. It is better to daub the relevant place within 60 minutes. Equality is very important in daubing, and the floor glue is semi-touchable, so it is ok just to press the tiles.'
        },
        {
          num: '08',
          content: 'After daubing and the glue is becoming a little dry (about 20 minutes), when it is not sticky in touching, install the tiles along the datum line. The tiles should be put in order and placed from top to bottom, then press around each tile to make it well agglutinated.'
        },
        {
          num: '09',
          content: 'If the glue overflows from the joint, wipe immediately with wet cloth, or it will be troublesome. Please take care. (If allowed by the environment, gasoline or diluent can also be ok.)'
        },
        {
          num: '10',
          content: 'When winding up, it needs to put one tile to the last floor tile that installed to make two inosculate well. Then get another as practice and make one side cling to the wall and cut the other side along the edge with art knife, and finally break along the cutting line.'
        },
        {
          num: '11',
          content: 'When the edge installation is finished, it needs to clean up the fragments; and press with roller to make the tiles inosculate flatly. Then wipe with wet cloth. If there is still something dirty, please clear with a little rosin water carefully and then with clean water.'
        },
        {
          num: '12',
          content: 'After installation, first clean up all the dirtiness, and then maintain the surface with acryl colophony to keep the tiles bright.'
        },
        {
          num: '13',
          content: 'In daily cleanness, clean water is ok. Waxing periodically to keep the floor tiles bright. Twice or three times each month in acryl colophony is very useful.'
        },


      ],
      serverList2: [
        {
          num: '01',
          content: 'Firstly clean up the floor tiles thoroughly; if there is glue or other dirt, rub out with neutral cleanser and 3M red long crooked squash cloth lightly.'
        },
        {
          num: '02',
          content: 'Sprinkle the floor cleanser onto the tiles (please follow the instructions), and rub out the dirt with waxing machine, then swab the floor. (If no waxing machine, other tool can also be used instead).'
        },
        {
          num: '03',
          content: 'After the floor is dry, pour the wax to the clean mop and then daub to the tiles equally.'
        },
        {
          num: '04',
          content: 'It can not be touched until 20 minutes later after the surface is dry. The fanner can be used to shorten the time. Generally, the surface will be completely dry 8 hours later. So it is better to move the heavy things after the wax is dry. It can keep the floor tiles fresh and bright.'
        },
        {
          num: '05',
          content: 'The four steps above are general methods. After a period of time, if the tiles become yellow, firstly it needs stripper before waxing again: douse wax stripper without water directly to the required place; 10-15 minutes later when stripper infiltrates the tiles, sprinkle water onto the tiles and clean the wax completely.'
        },
        {
          num: '06',
          content: 'After cleaning out the wax, please maintain the tiles according to the four steps above.'
        },
      ],
      serverList3: [
        {
          img: require('@/assets/images/all/pc-icon400.png'),
          content: 'Mark guide lines from centers of opposite wall.Without removing backing,lay rows of tiles in both directions from the center,adjusting if necessary to give the most economical and balanced cutting at borders.After a final check for dust,you are ready to start.',
          isright: false
        },
        {
          img: require('@/assets/images/all/pc-icon401.png'),
          content: 'Peel the paper backing from a tile and CAREFULLY place at your center point.Check that it is correctly positioned and then apply firm all-over pressure to achieve complete comtact.Lay all full tiles,ensuring a close fit but do not force tiles together.',
          isright: true
        },
        {
          img: require('@/assets/images/all/pc-icon402.png'),
          content: 'To fit part tiles,lay the ile to be cut exactly over the last full tile against the wall and mark the cutting line where the tiles over lap.Before peeling the backing paper,check that cut tile fits correctly.The tile should not be forced into place.',
          isright: false
        },
        {
          img: require('@/assets/images/all/pc-icon403.png'),
          content: 'To fit around pipes,door frames...etc.,just make a cardboard pattern as a guide and use this to draw an outline on the tile.Cut the tile in shape and check that it fits before peeling the backing paper.It should fit squarely and should not be forced into place.',
          isright: true
        },
        {
          img: require('@/assets/images/all/pc-icon404.png'),
          content: 'Sweep regularly to remove surface grit and dust .When necessary a mild detergent can be added to the water.DO NOT use adrasive cleaners.Stiletto heels can damage floors.Use protective pads under furniture.',
          isright: false
        },

      ]
    };
  }
}
</script>

<style lang="scss">
.view-service {
  .header-img-box {
    width: 100%;
    margin-bottom: 0.6rem;
  }
  .faq-list {
    padding: 0 3.1rem;
    margin-bottom: 1.5rem;
    .faq-title {
      font-size: 0.5rem;
      font-weight: 600;
      margin-bottom: 0.6rem;
      font-weight: 500;
      color: #000000;
      line-height: 0.59rem;
      .title {
        width: 10.7rem;
      }
    }
    .faq-line {
      margin-bottom: 0.6rem;
      .text-box {
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
        .img {
          width: 2rem;
          margin-right: 0.4rem;
        }
        .num {
          font-weight: 600;
          line-height: 0.28rem;
        }
        .text {
          font-weight: 300;
          font-size: 0.2rem;
          padding-bottom: 0.2rem;
        }
      }
      .text-box3 {
        display: flex;
        align-items: flex-end;
      }
      .flex-row {
        flex-direction: row-reverse;
        .img {
          width: 2rem;
          margin-right: 0;
          margin-left: 0.4rem;
        }
      }
    }
    .faq-line2 {
      margin-bottom: 1rem;
    }
    .center-img {
      margin-bottom: 0.6rem;
    }
    .notice-box {
      .notice-top {
        margin-bottom: 0.5rem;
        .notice-title {
          font-size: 0.3rem;
          font-weight: 600;
        }
      }
      .notice-main {
        font-size: 0.2rem;
        font-weight: 300;
        line-height: 0.23rem;
      }
    }
  }
}
</style>